var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.item.pathName == '/reports/accounts' && _vm.item.pathName == _vm.$route.path
    )?_c('svg',{staticClass:"mr-2",attrs:{"width":"7","height":"32","viewBox":"0 0 7 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"7","height":"26","rx":"3.5","fill":"#7D4CFF"}})]):_vm._e(),(
      _vm.item.pathName == '/reports/journals' && _vm.item.pathName == _vm.$route.path
    )?_c('svg',{staticClass:"mr-2",attrs:{"width":"7","height":"32","viewBox":"0 0 7 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"7","height":"26","rx":"3.5","fill":"#7D4CFF"}})]):_vm._e(),(
      _vm.item.pathName == '/reports/general-ledger' &&
      _vm.item.pathName == _vm.$route.path
    )?_c('svg',{staticClass:"mr-2",attrs:{"width":"7","height":"32","viewBox":"0 0 7 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"7","height":"26","rx":"3.5","fill":"#7D4CFF"}})]):_vm._e(),(
      _vm.item.pathName == '/reports/trial-balance' &&
      _vm.item.pathName == _vm.$route.path
    )?_c('svg',{staticClass:"mr-2",attrs:{"width":"7","height":"32","viewBox":"0 0 7 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"7","height":"26","rx":"3.5","fill":"#7D4CFF"}})]):_vm._e(),(
      _vm.item.pathName == '/reports/balance-sheet' &&
      _vm.item.pathName == _vm.$route.path
    )?_c('svg',{staticClass:"mr-2",attrs:{"width":"7","height":"32","viewBox":"0 0 7 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"7","height":"26","rx":"3.5","fill":"#7D4CFF"}})]):_vm._e(),(
      _vm.item.pathName == '/reports/profit-loss' && _vm.item.pathName == _vm.$route.path
    )?_c('svg',{staticClass:"mr-2",attrs:{"width":"7","height":"32","viewBox":"0 0 7 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"7","height":"26","rx":"3.5","fill":"#7D4CFF"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }