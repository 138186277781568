<template>
  <div>
    <!-- Selected tile svg -->
    <!-- Accounts -->
    <svg
      v-if="
        item.pathName == '/reports/accounts' && item.pathName == $route.path
      "
      width="7"
      height="32"
      viewBox="0 0 7 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <rect width="7" height="26" rx="3.5" fill="#7D4CFF" />
    </svg>

    <!-- Journals -->
    <svg
      v-if="
        item.pathName == '/reports/journals' && item.pathName == $route.path
      "
      width="7"
      height="32"
      viewBox="0 0 7 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <rect width="7" height="26" rx="3.5" fill="#7D4CFF" />
    </svg>

    <!-- GeneralLedger -->
    <svg
      v-if="
        item.pathName == '/reports/general-ledger' &&
        item.pathName == $route.path
      "
      width="7"
      height="32"
      viewBox="0 0 7 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <rect width="7" height="26" rx="3.5" fill="#7D4CFF" />
    </svg>

    <!-- TrialBalance -->
    <svg
      v-if="
        item.pathName == '/reports/trial-balance' &&
        item.pathName == $route.path
      "
      width="7"
      height="32"
      viewBox="0 0 7 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <rect width="7" height="26" rx="3.5" fill="#7D4CFF" />
    </svg>

    <!-- BalanceSheet -->
    <svg
      v-if="
        item.pathName == '/reports/balance-sheet' &&
        item.pathName == $route.path
      "
      width="7"
      height="32"
      viewBox="0 0 7 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <rect width="7" height="26" rx="3.5" fill="#7D4CFF" />
    </svg>

    <!-- ProfitAndLoss -->
    <svg
      v-if="
        item.pathName == '/reports/profit-loss' && item.pathName == $route.path
      "
      width="7"
      height="32"
      viewBox="0 0 7 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <rect width="7" height="26" rx="3.5" fill="#7D4CFF" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  mounted() {
    console.log(this.item, "icon", this.$route.name);
  },
};
</script>

<style lang="scss" scoped></style>
