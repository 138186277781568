<template>
  <div>
    <div
      v-if="currentWorkspace.connected"
      :class="$vuetify.breakpoint.xs ? 'mt-7' : 'd-flex flex-row'"
    >
      <div
        class="pr-0 ml-2"
        :style="
          $vuetify.breakpoint.xs
            ? 'display: none !important'
            : drawer
            ? 'width: 204px;'
            : 'width: 62px;'
        "
      >
        <!-- :style="
        ($vuetify.breakpoint.xs
          ? 'display: none !important'
          : 'display: block !important',
        drawer ? 'width:204px;' : 'width:62px;')
      " -->
        <v-navigation-drawer
          v-model="drawer"
          :clipped="true"
          :mini-variant="mini"
          permanent
          :value="drawer"
          mini-variant-width="62px"
          width="204"
          style="top: 68px !important"
        >
          <!-- fixed -->
          <v-list nav dense>
            <v-btn
              class="mb-3 ms-1"
              icon
              @click="
                mini = !mini;
                drawer = !drawer;
              "
            >
              <navigation-open
                :style="drawer ? 'transform: rotate(180deg)' : ''"
              ></navigation-open>
            </v-btn>
            <v-list-item
              v-for="(bar, index) in sideBar"
              :key="index"
              v-show="!bar.subBar"
              :to="bar.to"
              class="pl-0 mb-4"
            >
              <!-- :class="!drawer ? 'mr-5' : 'mr-0'" -->
              <v-list-item-icon
                class="nav-item-icon-space mr-3 mt-2"
                :class="bar.pathName === $route.path ? 'ml-0' : 'ml-3'"
              >
                <reports-icons
                  :item="bar"
                  class="d-flex flex-row align-center"
                />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  class="f-inter fw-400 fs-14"
                  :class="
                    bar.pathName === $route.path
                      ? 'lightPurple--text'
                      : 'dark--text'
                  "
                  >{{ bar.title }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!--Main category list-->
            <v-list-group
              v-for="(item, ind) in sideBar"
              :key="ind + 'A'"
              :value="true"
              no-action
              v-show="item.subBar"
            >
              <template v-slot:activator>
                <v-list-item-icon
                  class="nav-item-icon-space ml-1 mr-3 mt-2 justify-start"
                >
                  <svg
                    class="d-flex flex-row align-center ml-0"
                    width="20"
                    height="20"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.75878 5.30127C8.75878 5.14639 8.69725 4.99785 8.58773 4.88833C8.47822 4.77881 8.32968 4.71729 8.1748 4.71729H3.50293C3.34805 4.71729 3.19951 4.77881 3.08999 4.88833C2.98047 4.99785 2.91895 5.14639 2.91895 5.30127C2.91895 5.45615 2.98047 5.60469 3.08999 5.71421C3.19951 5.82372 3.34805 5.88525 3.50293 5.88525H8.1748C8.32968 5.88525 8.47822 5.82372 8.58773 5.71421C8.69725 5.60469 8.75878 5.45615 8.75878 5.30127ZM7.98013 7.6372C7.98013 7.48232 7.91861 7.33378 7.80909 7.22426C7.69957 7.11475 7.55103 7.05322 7.39615 7.05322H3.50293C3.34805 7.05322 3.19951 7.11475 3.08999 7.22426C2.98047 7.33378 2.91895 7.48232 2.91895 7.6372C2.91895 7.79208 2.98047 7.94062 3.08999 8.05014C3.19951 8.15966 3.34805 8.22119 3.50293 8.22119H7.39615C7.55103 8.22119 7.69957 8.15966 7.80909 8.05014C7.91861 7.94062 7.98013 7.79208 7.98013 7.6372ZM8.1748 9.38915C8.32968 9.38915 8.47822 9.45068 8.58773 9.5602C8.69725 9.66972 8.75878 9.81825 8.75878 9.97314C8.75878 10.128 8.69725 10.2766 8.58773 10.3861C8.47822 10.4956 8.32968 10.5571 8.1748 10.5571H3.50293C3.34805 10.5571 3.19951 10.4956 3.08999 10.3861C2.98047 10.2766 2.91895 10.128 2.91895 9.97314C2.91895 9.81825 2.98047 9.66972 3.08999 9.5602C3.19951 9.45068 3.34805 9.38915 3.50293 9.38915H8.1748Z"
                      fill="#2A2440"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.91992 14.6451H13.0423C13.6102 14.6451 14.1548 14.4195 14.5564 14.0179C14.958 13.6164 15.1836 13.0717 15.1836 12.5038V8.22129C15.1836 8.06641 15.122 7.91787 15.0125 7.80835C14.903 7.69884 14.7545 7.63731 14.5996 7.63731H12.069V1.55843C12.069 0.450421 10.8161 -0.194296 9.91448 0.449643L9.77822 0.546973C9.47983 0.758786 9.12284 0.872312 8.75692 0.871754C8.391 0.871196 8.03435 0.75658 7.73661 0.543859C7.23954 0.190096 6.6446 0 6.03449 0C5.42439 0 4.82945 0.190096 4.33238 0.543859C4.03464 0.75658 3.67799 0.871196 3.31207 0.871754C2.94615 0.872312 2.58916 0.758786 2.29077 0.546973L2.15451 0.449643C1.25284 -0.194296 0 0.449643 0 1.55843V11.7252C0 12.4996 0.307633 13.2423 0.855224 13.7899C1.40281 14.3375 2.14551 14.6451 2.91992 14.6451ZM5.01136 1.49381C5.31031 1.28158 5.66787 1.16757 6.03449 1.16757C6.40112 1.16757 6.75867 1.28158 7.05763 1.49381C7.5534 1.84818 8.14739 2.03902 8.75679 2.03972C9.36619 2.04042 9.96062 1.85094 10.4572 1.4977L10.5935 1.40037C10.6225 1.37966 10.6568 1.36735 10.6924 1.36478C10.728 1.36222 10.7636 1.36949 10.7953 1.38581C10.8271 1.40212 10.8537 1.42686 10.8724 1.4573C10.891 1.48775 10.9009 1.52273 10.901 1.55843V12.5038C10.901 12.8542 10.9851 13.1852 11.1346 13.4771H2.91992C2.45527 13.4771 2.00966 13.2926 1.6811 12.964C1.35255 12.6355 1.16797 12.1898 1.16797 11.7252V1.55843C1.16806 1.52273 1.17797 1.48775 1.19661 1.4573C1.21525 1.42686 1.2419 1.40212 1.27365 1.38581C1.3054 1.36949 1.34103 1.36222 1.37663 1.36478C1.41224 1.36735 1.44645 1.37966 1.47553 1.40037L1.61179 1.4977C2.10837 1.85094 2.7028 2.04042 3.3122 2.03972C3.9216 2.03902 4.51559 1.84818 5.01136 1.49381ZM12.069 12.5038V8.80528H14.0156V12.5038C14.0156 12.762 13.9131 13.0095 13.7305 13.1921C13.548 13.3746 13.3004 13.4771 13.0423 13.4771C12.7842 13.4771 12.5366 13.3746 12.3541 13.1921C12.1715 13.0095 12.069 12.762 12.069 12.5038Z"
                      fill="#2A2440"
                    />
                  </svg>
                </v-list-item-icon>

                <v-list-item-title class="f-inter fw-400 fs-14 pr-3 dark--text'"
                  >{{ item.title }}
                </v-list-item-title>
              </template>

              <v-list-item
                v-for="(subItem, ind) in item.subBar"
                :to="subItem.to"
                :key="ind + 'B'"
                class="pl-0"
              >
                <v-list-item-icon class="nav-item-icon-space mr-3 mt-4">
                  <selectedSubBar
                    :item="subItem"
                    class="d-flex flex-row align-center"
                  />
                </v-list-item-icon>

                <v-list-item-title class="pl-3">{{
                  subItem.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-navigation-drawer>

        <!-- <v-navigation-drawer
        :mini-variant="mini"
        permanent
        :value="drawer"
        mini-variant-width="62px"
        width="204"
        height="810"
        fixed
        style="top: 68px !important"
      >
        <v-list nav dense>
          <v-list-item-group v-model="selectedItem">
            <v-btn
              class="mb-3 ms-1"
              icon
              @click="
                mini = !mini;
                drawer = !drawer;
              "
            >
              <navigation-open
                :style="drawer ? 'transform: rotate(180deg)' : ''"
              ></navigation-open>
            </v-btn>
            <v-list-item
              class="px-0 drawer-tile"
              :style="drawer ? 'width: 189px;' : 'width: 48px;'"
              active-class="selected-tile-active"
              v-for="(item, index) in sideBar"
              :key="index"
              :to="item.to"
            >
              <v-list-item-icon class="nav-item-icon-space mr-3 mt-4 ml-3">
                <reports-icons
                  v-if="drawer && !item.subBar"
                  :routeName="item.routeName"
                />
                <v-tooltip bottom v-if="!drawer">
                  <template v-slot:activator="{ on, attrs }">
                    <reports-icons :item="item" v-bind="attrs" v-on="on" />
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content v-if="!item.subBar">
                <v-list-item-title
                  class="f-inter fw-400 fs-14"
                  :class="
                    item.routeName === $route.name
                      ? 'whiteColor--text'
                      : 'dark--text'
                  "
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-content>

              <v-list-group no-action v-if="item.subBar">
                <template v-slot:activator>
                  <reports-icons :routeName="'FinancialReports'" />
                  <v-list-item-content>{{ item.title }}</v-list-item-content>
                </template>
              </v-list-group>
              <v-list-item v-for="(sub, indexA) in subBar" :key="indexA">
                <v-list-item-content>{{ sub.title }}</v-list-item-content>
              </v-list-item>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer> -->
      </div>
      <!-- class="ml-4 mr-4" -->
      <div
        :style="
          drawer
            ? $vuetify.breakpoint.lgAndUp
              ? 'width: 86%;'
              : 'width: 100% !important'
            : 'width: 95%;'
        "
      >
        <router-view class=""></router-view>
      </div>
    </div>

    <no-software-connected v-if="!currentWorkspace.connected" />
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import NavigationOpen from "../components/common/icons/navigationDrawerOpen.vue";
import GenerateReport from "../components/generate_reports/tabs/GenerateReport.vue";
import ReportsIcons from "../assets/svg-icons/report-icons.vue";
import selectedSubBar from "../assets/svg-icons/report-subBar-selected-icon.vue";
import { mapGetters } from "vuex";
import NoSoftwareConnected from "../components/common/connect-software/NoSoftwareConnected.vue";
export default {
  name: "Reports",

  components: {
    RouterView,
    NavigationOpen,
    GenerateReport,
    ReportsIcons,
    selectedSubBar,
    NoSoftwareConnected,
  },

  data() {
    return {
      // heading: "Upcoming Reports",
      // upcomingReports: [
      //   {
      //     img: "int-1.svg",
      //     heading: "Profit & Loss",
      //     sub_heading: "Shows the company's revenues and expenses",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-2.svg",
      //     heading: "Cash summary",
      //     sub_heading: "See the movement of cash in and out of organisation",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "Accounts Receivable",
      //     sub_heading: "The money your customers owe to you",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "Accounts Payable",
      //     sub_heading: "The money you owe as a liability",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "Accounts Payable Aging",
      //     sub_heading: "Amount you owe to each contact",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "Balance sheet",
      //     sub_heading: "Financial position of your organization",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "Inventory details",
      //     sub_heading: "Sales and Purchase detail of inventory",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "VAT Report",
      //     sub_heading: "VAT report for your organization",
      //     to: "/dashboard",
      //   },
      //   {
      //     img: "int-3.svg",
      //     heading: "Custom report",
      //     sub_heading: "Custom report as per your requirement",
      //     to: "/dashboard",
      //   },
      // ],

      drawer: true,
      mini: false,
      sideBar: [
        {
          title: "Overview",
          to: "/reports",
          routeName: "Overview",
          pathName: "/reports",
          auth: true,
        },
        {
          title: "Financial Reports",
          to: "",
          routeName: "FinancialReports",
          pathName: "/reports",
          auth: true,
          subBar: [
            {
              title: "Accounts",
              to: "/reports/accounts",
              pathName: "/reports/accounts",
              routeName: "Accounts",
              auth: true,
              icon: "mdi-account",
            },
            {
              title: "Journals",
              to: "/reports/journals",
              pathName: "/reports/journals",
              routeName: "Journals",
              auth: true,
              icon: "mdi-account",
            },
            {
              title: "General Ledgers",
              to: "/reports/general-ledger",
              pathName: "/reports/general-ledger",
              routeName: "GeneralLedger",
              auth: true,
              icon: "mdi-account",
            },
            {
              title: "Trial Balance",
              to: "/reports/trial-balance",
              pathName: "/reports/trial-balance",
              routeName: "TrialBalance",
              auth: true,
              icon: "mdi-account",
            },
            {
              title: "Balance Sheet",
              to: "/reports/balance-sheet",
              pathName: "/reports/balance-sheet",
              routeName: "BalanceSheet",
              auth: true,
              icon: "mdi-account",
            },
            {
              title: "Profit and Loss",
              to: "/reports/profit-loss",
              pathName: "/reports/profit-loss",
              routeName: "ProfitAndLoss",
              auth: true,
              icon: "mdi-account",
            },
          ],
        },
        // {
        //   title: "Fp&A",
        //   to: "/reports/fpa",
        //   routeName: "FPA",
        //   pathName: "/reports/fpa",
        //   auth: true,
        // },
        // {
        //   title: "Accounting",
        //   to: "/reports/accounting",
        //   routeName: "Accounting",
        //   pathName: "/reports/accounting",
        //   auth: true,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  watch: {
    $route: {
      handler(val) {
        const parent = val.matched[val.matched.length - 2];
        localStorage.setItem("prevRoute", parent.name);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.$router.push({ path: "/overview" });
  },
};
</script>
<style scoped>
.drawer-tile {
  height: 48px;
  width: 48px;
}
.theme--light.v-list-item--active::before {
  opacity: 0;
}
/* .selected-tile-active {
  border-radius: 6px;
  background-color: var(--v-lightPurple-base) !important;
} */
.nav-item-icon-space {
  align-self: center;
}
/* .side-drawer {
  height: 810px !important;
} */

/* .v-tab {
  font-size: 12px !important;
  font-weight: 600 !important;
  justify-content: left !important;
} */
/* :deep(.v-tabs--vertical > .v-tabs-bar .v-tab) {
  border-left: 1px solid #e8ebf6;
} */
/* :deep(.v-tabs--vertical > .v-tabs-bar .v-tabs-slider) {
  height: 62% !important;
  margin-top: 8px !important;
} */

/* generate report component style */
/* .v-sheet.v-card {
  border-radius: 8px !important;
} */
</style>
